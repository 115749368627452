// See https://github.com/CrystallizeAPI/service-api-boilerplate/blob/main/src/services/crystallize/orders/create-order.js
import bent from "bent"

const CRYSTALLIZE_TENANT_IDENTIFIER = process.env.CRYSTALLIZE_TENANT_IDENTIFIER
const CRYSTALLIZE_ACCESS_TOKEN_ID = process.env.CRYSTALLIZE_ACCESS_TOKEN_ID
const CRYSTALLIZE_ACCESS_TOKEN_SECRET =
  process.env.CRYSTALLIZE_ACCESS_TOKEN_SECRET

function createApiCaller(uri) {
  return async function callApi({ query, variables, operationName }) {
    const post = bent(uri, "POST", "json", 200, 204, {
      "content-type": "application/json",
      "X-Crystallize-Access-Token-Id": CRYSTALLIZE_ACCESS_TOKEN_ID,
      "X-Crystallize-Access-Token-Secret": CRYSTALLIZE_ACCESS_TOKEN_SECRET,
    })
    try {
      const response = await post("", { operationName, query, variables })
      return response
    } catch (error) {
      console.error(error)
    }
  }
}

export const getTenantId = (function () {
  let tenantId

  return async () => {
    if (tenantId) {
      return tenantId
    }

    const tenantIdResponse = await callCatalogueApi({
      query: `
          {
            tenant {
              id
            }
          }
        `,
    })
    tenantId = tenantIdResponse.data.tenant.id

    return tenantId
  }
})()

/**
 * Catalogue API is the fast read-only API to lookup data
 * for a given item path or anything else in the catalogue
 */
export const callCatalogueApi = createApiCaller(
  `https://api.crystallize.com/${CRYSTALLIZE_TENANT_IDENTIFIER}/catalogue`
)

/**
 * Search API is the fast read-only API to search across
 * all items and topics
 */
export const callSearchApi = createApiCaller(
  `https://api.crystallize.com/${CRYSTALLIZE_TENANT_IDENTIFIER}/search`
)

/**
 * Orders API is the highly scalable API to send/read massive
 * amounts of orders
 */
export const callOrdersApi = createApiCaller(
  `https://api.crystallize.com/${CRYSTALLIZE_TENANT_IDENTIFIER}/orders`
)

/**
 * The PIM API is used for doing the ALL possible actions on
 * a tenant or your user profile
 */
export const callPimApi = createApiCaller("https://pim.crystallize.com/graphql")
