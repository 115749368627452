/** @jsx jsx */
import { jsx } from "theme-ui"

import theme from "../../gatsby-plugin-theme-ui"
import { ArticleIframeEmbedPropTypes } from "../../utils/propTypes"
import { RichText } from "../RichText"
import { heightFromDeviceHeight } from "../../utils/styleUtils"

export const ArticleIframeEmbed = ({ primary }) => {
  const { iframe_link, caption } = primary

  return (
    <div>
      <iframe
        title={caption.text}
        loading="lazy"
        src={iframe_link.url}
        allowFullScreen={true}
        frameBorder={0}
        sx={{ width: "100vw", ...heightFromDeviceHeight(0.7) }}
      />
      {caption?.richText ? (
        <RichText
          sxOverrides={{
            ...theme.articlePageStyles.containedElement,
            textAlign: "center",
            variant: "text.fineprint",
            color: "gray",
          }}
          text={caption}
        />
      ) : null}
    </div>
  )
}

ArticleIframeEmbed.propTypes = ArticleIframeEmbedPropTypes
