/** @jsx jsx */

import PropTypes from "prop-types"
import { Heading, Flex, Text, jsx } from "theme-ui"
import { RiSeedlingLine } from "react-icons/ri"

import theme from "../../gatsby-plugin-theme-ui"
import { TransformedArticlePropTypes } from "../../utils/propTypes"
import { MediaMatcher } from "../MediaMatcher"
import { Link } from "../Link"
import { ResponsiveImage } from "../ResponsiveImage"
import { ArticleBriefCard } from "../ArticleBriefCard"
import { ItemsGrid } from "../ItemsGrid"
import { MobileItemsCarousel } from "../MobileItemsCarousel"
import { seedlingsAndSoilBackground } from "../../hooks/seedlingsAndSoilBackground"

export const MoreArticles = ({ articles }) => {
  const bgImage = seedlingsAndSoilBackground()
  return (
    <ResponsiveImage
      isBackgroundImage={true}
      image={bgImage}
      sx={{
        backgroundPosition: "top",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        pt: ["100px"],
      }}
      bgImgStyles={{
        backgroundSize: "auto 100%",
        backgroundRepeat: "repeat-x",
      }}
    >
      <Heading
        as="h1"
        variant="headingSandYellow"
        sx={{
          ...theme.articlePageStyles.container.base,
          mb: 3,
          textAlign: ["left", "left", "center"],
        }}
      >
        {"Dig Around"}
      </Heading>
      <MediaMatcher.ServerRender predicted="mobilePortrait">
        <MediaMatcher.Matcher
          mobilePortrait={
            <MobileItemsCarousel
              items={articles}
              component={ArticleBriefCard}
              itemProps={{ displayBadges: true }}
            />
          }
          tablet={
            <div sx={{ ...theme.basePageStyles.contentContainer }}>
              <ItemsGrid
                items={articles}
                component={ArticleBriefCard}
                emphasizeFirst={false}
                itemProps={{ displayBadges: true }}
              />
            </div>
          }
        />
      </MediaMatcher.ServerRender>
      <Flex
        sx={{
          ...theme.articlePageStyles.container.base,
          mt: 3,
          mb: 4,
          justifyContent: ["flex-start", "flex-start", "center"],
        }}
      >
        <Link variant="buttonPrimary" to="/articles/1">
          <Flex sx={{ alignItems: "center" }}>
            <Text sx={{ mr: 1 }}>Enter the forest</Text>
            <RiSeedlingLine />
          </Flex>
        </Link>
      </Flex>
    </ResponsiveImage>
  )
}
MoreArticles.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape(TransformedArticlePropTypes)),
}
