/** @jsx jsx */

import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import { Layout } from "../components/Layout"
import {
  extractArticleSeoFields,
  extractBaseSeoFields,
} from "../utils/seoUtils"
import { ArticlePropTypes } from "../utils/propTypes"
import { ArticleDetail } from "../components/article/ArticleDetail"
import { MoreArticles } from "../components/article/MoreArticles"
import {
  transformArticlesData,
  transformArticleData,
} from "../utils/articleUtils"
import { articles } from "../utils/testDataUtils"

// Since the query for more articles is done at build-time
// inside gatsby-node.js, it is unavaiable during unpublished preview
// and has to be mocked
const mockMoreArticlesForUnpublishedPreview = () => ({
  edges: articles([
    {
      title: "Mock related article 1",
    },
    {
      title: "Mock related article 2",
    },
    {
      title: "Mock related article 3",
    },
    {
      title: "Mock related article 4",
    },
  ]).map(article => ({ node: article })),
})

export const ArticlePageTemplate = ({ data }) => {
  const article = transformArticleData(data.prismicArticle)
  const seoProps = {
    ...extractBaseSeoFields({
      uid: data.prismicArticle.uid,
      type: data.prismicArticle.type,
      ...article,
    }),
    ...extractArticleSeoFields(article),
  }
  const moreArticles = transformArticlesData(
    data.allPrismicArticle || mockMoreArticlesForUnpublishedPreview()
  )

  return (
    <Layout seoProps={seoProps}>
      <ArticleDetail article={article} />
      <MoreArticles articles={moreArticles} />
    </Layout>
  )
}

ArticlePageTemplate.propTypes = {
  data: PropTypes.shape({
    prismicArticle: PropTypes.shape(ArticlePropTypes).isRequired,
    allPrismicArticle: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape(ArticlePropTypes),
        })
      ),
    }).isRequired,
  }).isRequired,
}
