/** @jsx jsx */
import { useState } from "react"
import { jsx, Flex, Button, Heading, Input } from "theme-ui"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useMutation } from "react-query"
import { Formik, Form } from "formik"

import theme from "../../gatsby-plugin-theme-ui"
import { Divider } from "../Divider"
import { DividerType } from "../../hooks/divider"
import { Link } from "../Link"
import { withRecaptcha } from "../../utils/recaptchaUtils"
import { loadRecaptcha } from "../../utils/recaptchaUtils"
import { FormikFieldWithError } from "../FormikFieldWithError"
import { SeedlingLoader } from "../SeedlingLoader"
import { ApiClient } from "../../utils/apiClient"
import { useFormAlertContext } from "../../hocs/FormAlertProvider"

const JournazineArticleAccessForm = ({
  journazineUid,
  updateAccessState,
  containerStyles,
}) => {
  const { updateAlertState } = useFormAlertContext()
  const verifyAccess = useMutation(
    payload => ApiClient.post("/api/verify-journazine-access", payload),
    {
      onSuccess: data => {
        localStorage.setItem(
          `journazine-${journazineUid}-access-token`,
          data.jwt
        )
        updateAccessState(prevState => ({
          ...prevState,
          checkAccessAgain: true,
        }))
        updateAlertState({
          result: "success",
          msg: "Your access has been verified!",
          isVisible: true,
        })
      },
      onError: async error => {
        updateAlertState({
          ...(await error.json()),
          isVisible: true,
        })
      },
    }
  )

  return (
    <div
      sx={{
        ...containerStyles,
        backgroundColor: "darkSoilBrown",
        px: "10%",
      }}
    >
      <Formik
        initialValues={{
          email: "",
          accessCode: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string()
            .email("Please provide a valid email address")
            .required("Required"),
          accessCode: Yup.string().required("Required"),
        })}
        onSubmit={async values => {
          const action = "accessjournazinearticle"
          withRecaptcha(action, async token => {
            const payload = {
              recaptchaToken: token,
              action,
              email: values.email,
              accessCode: values.accessCode,
              journazineUid,
            }

            verifyAccess.mutate(payload)
          })
        }}
      >
        <Form>
          <FormikFieldWithError
            component={Input}
            name="email"
            id="email"
            type="text"
            fieldVariant="inputWhiteNoLabel"
            placeholder="Email"
            aria-label="Email"
            onFocus={loadRecaptcha}
          />
          <FormikFieldWithError
            component={Input}
            name="accessCode"
            id="accessCode"
            type="password"
            fieldVariant="inputWhiteNoLabel"
            placeholder="Access code"
            aria-label="Access code"
          />
          <Flex
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 5,
            }}
          >
            {verifyAccess.isLoading ? (
              <SeedlingLoader />
            ) : (
              <Button type="submit" variant="primary" sx={{ px: 5 }}>
                Submit
              </Button>
            )}
          </Flex>
        </Form>
      </Formik>
    </div>
  )
}
JournazineArticleAccessForm.propTypes = {
  journazineUid: PropTypes.string,
  updateAccessState: PropTypes.func,
  containerStyles: PropTypes.object,
}

export const PaidArticleFence = ({
  journazineUid,
  journazineBuyUrl,
  updateAccessState,
}) => {
  const [showAccessForm, updateShowAccessForm] = useState(false)

  const onEnterAccessCode = () => {
    updateShowAccessForm(true)
  }

  return (
    <div
      sx={{
        ...theme.articlePageStyles.container.base,
        position: "relative",
        pt: "200px",
        mb: "-150px",
        top: "-200px",
        background: `linear-gradient(to top,
              ${theme.colors.background} 65%,
              transparent
              )
              `,
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Divider type={DividerType.DEFAULT_OLIVE} />
        <Heading variant="subheading" sx={{ my: 5, textAlign: "center" }}>
          Sorry, but this article is for Sauciers only. Enter your access code
          or get online access to this issue of The Sauce to read the rest of
          this article.
        </Heading>
        <Flex
          sx={{
            justifyContent: "center",
            my: 3,
          }}
        >
          <Link
            variant="buttonOlive"
            to={journazineBuyUrl}
            sx={{ mr: 3, px: [3, 3, 4, 5], fontSize: 0 }}
          >
            Get Access
          </Link>
          <Button
            variant="oliveTransparent"
            onClick={onEnterAccessCode}
            sx={{ ml: 0, px: [3, 3, 4, 5], fontSize: 0 }}
          >
            Enter Access Code
          </Button>
        </Flex>
      </Flex>
      <JournazineArticleAccessForm
        journazineUid={journazineUid}
        updateAccessState={updateAccessState}
        containerStyles={{
          transition: "all 0.5s ease-out",
          maxHeight: showAccessForm ? "500px" : "0",
          py: showAccessForm ? 5 : 0,
          overflow: "hidden",
          "*": {
            display: showAccessForm ? null : "none",
          },
        }}
      />
    </div>
  )
}
PaidArticleFence.propTypes = {
  journazineUid: PropTypes.string,
  journazineBuyUrl: PropTypes.string,
  updateAccessState: PropTypes.func,
}
