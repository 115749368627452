/** @jsx jsx */
import { useState } from "react"
import { Heading, Flex, IconButton, Alert, Text, jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
} from "react-share"
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiOutlineTwitter,
  AiOutlineWhatsApp,
  AiOutlineLink,
} from "react-icons/ai"
import { RiTelegramLine } from "react-icons/ri"

import theme from "../../gatsby-plugin-theme-ui"
import { PrismicPlainTextFieldPropType } from "../../utils/propTypes"

const LinkCopiedAlert = ({ isVisible }) => (
  <Alert
    data-testid="link-copied-alert"
    variant="default"
    sx={{
      opacity: isVisible ? 0.9 : 0,
      visibility: isVisible ? "visible" : "hidden",
    }}
  >
    <Text>Link copied!</Text>
  </Alert>
)
LinkCopiedAlert.propTypes = {
  isVisible: PropTypes.bool,
}

export const ArticleSharingLinks = ({ title, excerpt, url }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )
  const fullUrl = `${site.siteMetadata.siteUrl}${url}`
  const hashtag = "foodscapepages"
  const [showLinkCopied, updateShowLinkCopied] = useState(false)

  const copyLinkOnClick = () => {
    navigator.clipboard.writeText(fullUrl)
    updateShowLinkCopied(true)
    setTimeout(() => updateShowLinkCopied(false), 1000)
  }

  return (
    <Flex
      sx={{
        flexDirection: "column",
        ...theme.articlePageStyles.container.base,
      }}
    >
      <LinkCopiedAlert isVisible={showLinkCopied} />
      <Heading variant="headingOlive">Share this article</Heading>
      <Flex sx={{ mt: 4, justifyContent: "space-between" }}>
        <FacebookShareButton
          url={fullUrl}
          quote={title.text}
          hashtag={hashtag}
          resetButtonStyle={false}
          sx={{ ...theme.buttons.reset, ...theme.links.iconDark }}
        >
          <AiFillFacebook size={32} />
        </FacebookShareButton>
        <TwitterShareButton
          url={fullUrl}
          title={title.text}
          hashtag={hashtag}
          resetButtonStyle={false}
          sx={{ ...theme.buttons.reset, ...theme.links.iconDark }}
        >
          <AiOutlineTwitter size={32} />
        </TwitterShareButton>
        <LinkedinShareButton
          url={fullUrl}
          source="Foodscape Pages"
          title={title.text}
          summary={excerpt}
          resetButtonStyle={false}
          sx={{ ...theme.buttons.reset, ...theme.links.iconDark }}
        >
          <AiFillLinkedin size={32} />
        </LinkedinShareButton>
        <WhatsappShareButton
          url={fullUrl}
          title={title.text}
          separator=" | "
          resetButtonStyle={false}
          sx={{ ...theme.buttons.reset, ...theme.links.iconDark }}
        >
          <AiOutlineWhatsApp size={32} />
        </WhatsappShareButton>
        <TelegramShareButton
          url={fullUrl}
          title={title.text}
          resetButtonStyle={false}
          sx={{ ...theme.buttons.reset, ...theme.links.iconDark }}
        >
          <RiTelegramLine size={32} />
        </TelegramShareButton>
        <IconButton
          aria-label="copy-link"
          onClick={copyLinkOnClick}
          sx={{ ...theme.buttons.reset, ...theme.links.iconDark }}
        >
          <AiOutlineLink size={32} />
        </IconButton>
      </Flex>
    </Flex>
  )
}
ArticleSharingLinks.propTypes = {
  title: PrismicPlainTextFieldPropType,
  excerpt: PropTypes.string,
  url: PropTypes.string,
}
