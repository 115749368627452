/** @jsx jsx */
import { Fragment, useState, useEffect } from "react"
import { Heading, jsx } from "theme-ui"
import PropTypes from "prop-types"
import { jwtVerify } from "jose/jwt/verify"

import theme from "../../gatsby-plugin-theme-ui"
import { PrismicPlainTextFieldPropType } from "../../utils/propTypes"
import { TransformedArticlePropTypes } from "../../utils/propTypes"
import { extractExcerpt } from "../../utils/articleUtils"
import { Article } from "../../enums/Article"
import { ArticleTextWithQuote } from "./ArticleTextWithQuote"
import { ArticleText } from "./ArticleText"
import { ArticleCallout } from "./ArticleCallout"
import { ArticleImages } from "./ArticleImages"
import { ArticleDivider } from "./ArticleDivider"
import { ArticleImagesWithQuote } from "./ArticleImagesWithQuote"
import { ArticleQuote } from "./ArticleQuote"
import { ArticleImageGallery } from "./ArticleImageGallery"
import { ArticleIframeEmbed } from "./ArticleIframeEmbed"
import { ArticleSharingLinks } from "./ArticleSharingLinks"
import { Tags } from "../Tags"
import { ContributorsArticle, ContributorsFull } from "../Contributors"
import { heightFromDeviceHeight } from "../../utils/styleUtils"
import { ResponsiveImage } from "../ResponsiveImage"
import { RichText } from "../RichText"
import { DividerType } from "../../hooks/divider"
import { Divider } from "../Divider"
import { PaidArticleFence } from "./PaidArticleFence"
import { getServerPublicKey } from "../../utils/jwtKeys"
import { TOKEN_MAX_AGE } from "../../api/verify-journazine-access"

const renderContentSlice = (content_slice, key) => {
  const props = { key, ...content_slice }
  switch (content_slice.__typename) {
    case Article.ContentSliceTypes.DIVIDER:
      return <ArticleDivider {...props} />
    case Article.ContentSliceTypes.QUOTE:
      return <ArticleQuote {...props} />
    case Article.ContentSliceTypes.TEXT:
      return <ArticleText {...props} />
    case Article.ContentSliceTypes.TEXT_WITH_QUOTE:
      return <ArticleTextWithQuote {...props} />
    case Article.ContentSliceTypes.CALLOUT:
      return <ArticleCallout {...props} />
    case Article.ContentSliceTypes.IMAGES:
      return <ArticleImages {...props} />
    case Article.ContentSliceTypes.IMAGES_WITH_QUOTE:
      return <ArticleImagesWithQuote {...props} />
    case Article.ContentSliceTypes.IMAGE_GALLERY:
      return <ArticleImageGallery {...props} />
    case Article.ContentSliceTypes.IFRAME:
      return <ArticleIframeEmbed {...props} />
    default:
      return null
  }
}

const ArticleTitle = ({ title }) => (
  <Heading
    variant="headingArticle"
    sx={{ ...theme.articlePageStyles.containedElement, ml: [0, 0, "auto"] }}
  >
    {title.text}
  </Heading>
)
ArticleTitle.propTypes = {
  title: PrismicPlainTextFieldPropType,
}

export const ArticleDetail = ({ article }) => {
  const {
    title,
    publication_date,
    first_publication_date,
    cover_image,
    cover_iframe_link,
    needs_paid_access,
    open_call,
    journazine_issue,
    tags,
    contributors,
    body,
  } = article
  const publishedDate = publication_date || first_publication_date
  const excerpt = extractExcerpt(body, 100)
  const [accessState, updateAccessState] = useState({
    hasAccess: false,
    checkAccessAgain: false,
  })

  useEffect(() => {
    if (needs_paid_access) {
      const accessToken = localStorage.getItem(
        `journazine-${journazine_issue.document.uid}-access-token`
      )
      const verifyJwt = async () => {
        try {
          const serverPublicKey = await getServerPublicKey()
          await jwtVerify(accessToken, serverPublicKey, {
            issuer: process.env.GATSBY_SITE_URL,
            maxTokenAge: TOKEN_MAX_AGE,
          })
          updateAccessState(prevState => ({ ...prevState, hasAccess: true }))
        } catch (error) {
          if (process.env.NODE_ENV === "development") {
            console.error(error)
          }
        }
      }

      verifyJwt()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessState.checkAccessAgain])

  return (
    <Fragment>
      {cover_iframe_link.url ? (
        <iframe
          title="cover iframe"
          loading="lazy"
          src={cover_iframe_link.url}
          allowFullScreen={true}
          frameBorder={0}
          sx={{ width: "100vw", ...heightFromDeviceHeight(0.7) }}
        />
      ) : (
        <ResponsiveImage
          image={cover_image}
          sx={{ width: "100vw", ...heightFromDeviceHeight(0.7) }}
        />
      )}
      <ArticleTitle title={title} />
      <ContributorsArticle
        contributors={contributors}
        publishedDate={publishedDate}
        styles={{ ...theme.articlePageStyles.container.base, my: 3 }}
      />
      <Divider
        type={DividerType.DEFAULT}
        styles={theme.articlePageStyles.containedElement}
      />
      {journazine_issue.document ? (
        <Fragment>
          <RichText
            sxOverrides={{
              variant: "text.fineprint",
              color: "gray",
              fontStyle: "italic",
              ...theme.articlePageStyles.container.base,
            }}
            text={journazine_issue.document.data.article_issue_description}
          />
          <Divider
            type={DividerType.DEFAULT}
            styles={theme.articlePageStyles.containedElement}
          />
        </Fragment>
      ) : null}
      {needs_paid_access && !accessState.hasAccess ? (
        <Fragment>
          {body
            .slice(0, 2)
            .map((content_slice, i) => renderContentSlice(content_slice, i))}
          <PaidArticleFence
            journazineUid={journazine_issue.document.uid}
            journazineBuyUrl={`/products${journazine_issue.document.data.crystallize_product_catalogue_path}`}
            updateAccessState={updateAccessState}
          />
        </Fragment>
      ) : (
        <Fragment>
          {body.map((content_slice, i) => renderContentSlice(content_slice, i))}
          {open_call.document ? (
            <Fragment>
              <Divider
                type={DividerType.DEFAULT}
                styles={theme.articlePageStyles.containedElement}
              />
              <RichText
                sxOverrides={{
                  variant: "text.fineprint",
                  color: "gray",
                  fontStyle: "italic",
                  ...theme.articlePageStyles.container.base,
                }}
                text={open_call.document.data.article_description}
              />
            </Fragment>
          ) : null}
          <Tags tags={tags} styles={theme.articlePageStyles.container.base} />
          <Divider
            type={DividerType.DEFAULT}
            styles={{ ...theme.articlePageStyles.containedElement, mt: 4 }}
          />
          <div sx={{ ...theme.articlePageStyles.container.base, mb: -2 }}>
            <Heading variant="headingOlive">
              About the author{contributors.length > 1 ? "s" : ""}
            </Heading>
            <ContributorsFull contributors={contributors} />
          </div>
          <ArticleSharingLinks
            title={title}
            excerpt={excerpt}
            url={article.url}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

ArticleDetail.propTypes = {
  article: PropTypes.shape(TransformedArticlePropTypes).isRequired,
}
