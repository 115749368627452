import { SignJWT } from "jose/jwt/sign"

import { hasPassedRecaptcha } from "../utils/api/recaptcha"
import { authenticateAppClient } from "../utils/api/middleware"
import { getCustomerOrders } from "../utils/api/crystallize/getCustomerOrders"
import { Crystallize } from "../enums/Crystallize"
import { getServerPrivateKey } from "../utils/jwtKeys"

export const TOKEN_MAX_AGE = "15d"

export default async function handler(req, res) {
  if (!(await authenticateAppClient(req, res))) {
    return
  }

  const payload = req.body

  if (req.method === "POST") {
    try {
      if (await hasPassedRecaptcha(payload.recaptchaToken, payload.action)) {
        const { journazineUid, email, accessCode } = payload

        const customerOrders = await getCustomerOrders(email)
        const orderWithAccessCode = customerOrders.find(order =>
          order.meta.find(data => {
            return (
              data.key ===
                `${journazineUid}:${Crystallize.OrderMetaKey.ONLINE_ACCESS_CODE}` &&
              data.value === accessCode
            )
          })
        )

        if (!orderWithAccessCode) {
          return res.status(401).json({
            result: "error",
            msg: "Your email and access code don't seem to be valid",
          })
        }

        const privateKey = await getServerPrivateKey()
        const jwt = await new SignJWT({ email })
          .setProtectedHeader({ alg: "ES256" })
          .setIssuedAt()
          .setIssuer(process.env.GATSBY_SITE_URL)
          .setExpirationTime(TOKEN_MAX_AGE)
          .sign(privateKey)

        res.status(201).json({
          jwt,
        })
      } else {
        res.status(400).json({
          result: "error",
          msg: "Recaptcha check failed",
        })
      }
    } catch (error) {
      console.error(error)
      res.status(500).json({
        result: "error",
        msg: "Something seems to have gone wrong. Please try again!",
      })
    }
  } else {
    res.status(400).send("Unsupported HTTP method")
  }
}
