/** @jsx jsx */

import { graphql } from "gatsby"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

import { ArticlePageTemplate } from "./ArticlePageTemplate"
import linkResolver from "../utils/linkResolver"

export const query = graphql`
  query ArticleQuery($id: String, $currentArticleCategoryIds: [ID]) {
    prismicArticle(id: { eq: $id }) {
      _previewable
      ...Article
    }
    allPrismicArticle(
      limit: 4
      filter: {
        data: {
          tags: {
            elemMatch: { category: { id: { in: $currentArticleCategoryIds } } }
          }
        }
        id: { ne: $id }
      }
      sort: {
        fields: [data___publication_date, first_publication_date]
        order: [DESC, DESC]
      }
    ) {
      edges {
        node {
          ...ArticleCard
        }
      }
    }
  }
`

export default withPrismicPreview(ArticlePageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])