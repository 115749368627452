import { OpenGraphTypes } from "./seoUtils"
import { Article } from "../enums/Article"
import mockImage from "../images/navbar-background.svg"
import { transformProduct } from "./eCommerceUtils"
import { Crystallize } from "../enums/Crystallize"

export const image = alt => ({
  alt: alt || "some alt",
  gatsbyImageData: {
    images: {
      sources: [],
      fallback: {
        src: mockImage,
        srcSet: `${mockImage} 100w`,
        sizes: "(min-width: 800px) 800px, 100vw",
      },
    },
    layout: "constrained",
    width: 800,
    height: 650,
  },
})

export const articleTags = tagNames =>
  tagNames.map(name => ({
    category: {
      document: {
        data: {
          name,
        },
      },
    },
  }))

export const articleContributors = contributorProps =>
  contributorProps.map(props => ({
    contributor: {
      document: {
        uid: `${props.name}-uid`,
        data: {
          name: props.name,
          profile_picture: image(
            props.profile_pic_alt || "contributor profile alt"
          ),
          biography: {
            richText: [
              {
                type: "paragraph",
                text:
                  props.biography ||
                  " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce malesuada  id turpis eget porta. Etiam consequat sapien pulvinar aliquam  consequat. In ut urna laoreet, pharetra arcu facilisis, vulputate quam.  Curabitur purus dui, condimentum id est a, cursus aliquam leo. Duis  vehicula risus nec nisi fringilla, ut tincidunt nunc rutrum. Vivamus et  nunc vitae nisl laoreet dictum. Aenean sit amet faucibus lectus. In  vitae feugiat tortor, eget malesuada sem. Fusce egestas, erat quis  consectetur imperdiet, massa nisi placerat neque, sed dignissim nunc  tellus nec mauris. Nulla risus ante, posuere ac tempor quis, accumsan at  leo. Mauris quis faucibus sem. ",
                spans: [],
              },
            ],
          },
          is_core_team_member: props.is_core_team_member || false,
          email_link: {
            url: props.email_link || "",
          },
          instagram_link: {
            url: props.instagram_link || "",
          },
          facebook_link: {
            url: props.facebook_link || "",
          },
          twitter_link: {
            url: props.twitter_link || "",
          },
          linkedin_link: {
            url: props.linkedin_link || "",
          },
          website_links: props.website_links || [],
        },
      },
    },
  }))

export const articleOpenCall = title => ({
  document: {
    data: {
      title: {
        text: title,
      },
      article_description: {
        richText: [
          {
            type: "paragraph",
            text: "Some article description",
            spans: [],
          },
        ],
      },
    },
  },
})

export const articleJournazine = issue_title => ({
  document: {
    data: {
      issue_title: {
        text: issue_title,
      },
      article_issue_description: {
        richText: [
          {
            type: "paragraph",
            text: "Some article description",
            spans: [],
          },
        ],
      },
    },
  },
})

export const articleBody = bodyText => [
  {
    __typename: Article.ContentSliceTypes.TEXT,
    primary: {
      text: {
        richText: [
          {
            type: "paragraph",
            text:
              bodyText ||
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce malesuada  id turpis eget porta. Etiam consequat sapien pulvinar aliquam  consequat. In ut urna laoreet, pharetra arcu facilisis, vulputate quam.  Curabitur purus dui, condimentum id est a, cursus aliquam leo. Duis  vehicula risus nec nisi fringilla, ut tincidunt nunc rutrum. Vivamus et  nunc vitae nisl laoreet dictum. Aenean sit amet faucibus lectus. In  vitae feugiat tortor, eget malesuada sem. Fusce egestas, erat quis  consectetur imperdiet, massa nisi placerat neque, sed dignissim nunc  tellus nec mauris. Nulla risus ante, posuere ac tempor quis, accumsan at  leo. Mauris quis faucibus sem. ",
            spans: [],
          },
        ],
      },
    },
  },
]

export const articles = articleProps =>
  articleProps.map(props => ({
    uid: props.uid || "article1",
    type: OpenGraphTypes.ARTICLE,
    url: props.uid ? `/articles/${props.uid}` : "/articles/article1",
    first_publication_date: props.first_publication_date || "12 June 2021",
    last_publication_date: "11 June 2021",
    data: {
      canonical_url: {
        url: "",
      },
      language: "cn-SG",
      is_featured: props.isFeatured || false,
      cover_image: {
        ...image(),
        thumbnails: {
          mobile_portrait: image(),
          mobile_landscape: image(),
        },
      },
      cover_iframe_link: {
        url: null,
      },
      publication_date: null,
      title: {
        text: props.title,
      },
      body: articleBody(props.bodyText),
      open_call: articleOpenCall("Some open call"),
      journazine_issue: articleJournazine("Some journazine issue"),
      tags: props.tags || articleTags(props.tagNames || ["tag-one", "tag-two"]),
      contributors:
        props.contributors ||
        articleContributors(
          props.contributorProps || [
            { name: "contributor one" },
            { name: "contributor two" },
          ]
        ),
    },
  }))

export const category = (uid, name) => ({
  uid,
  name,
  image: image(),
})

export const categoryWithNode = (uid, name) => ({
  node: {
    uid,
    data: {
      name,
    },
  },
})

export const journazines = journazineProps =>
  journazineProps.map((props, i) => ({
    type: "journazine",
    uid: props.uid || `journazine-${i}`,
    url: `/journazines/${props.uid || `journazine-${i}`}`,
    data: {
      cover_image: {
        ...image(),
        thumbnails: {
          mobile_portrait: image(),
          mobile_landscape: image(),
        },
      },
      publication_date: null,
      issue_number: props.issue_number || "Some Issue No.",
      issue_title: {
        text: props.issue_title,
      },
      issue_description: {
        richText: [
          {
            type: "paragraph",
            text: props.issue_description || "some description",
            spans: [],
          },
        ],
      },
      short_issue_description: {
        richText: [
          {
            type: "paragraph",
            text: props.short_issue_description || "some short description",
            spans: [],
          },
        ],
        text: props.short_issue_description || "some short description",
      },
      preview_link: props.preview_link || {
        url: "",
      },
      sections: props.sections || [
        {
          section_name: "Section 1",
        },
        {
          section_name: "Section 2",
        },
      ],
      crystallize_product_catalogue_path:
        props.crystallize_product_catalogue_path ||
        `/journazines/${props.uid || `journainzes-${i}`}`,
    },
  }))

export const mockFallbackImage = {
  prismicFallbackImage: {
    data: {
      image: image(),
    },
  },
}

export const crystallizeImageVariant = width => ({
  key: `some-tenant/21/9/6/1/@${width}/the-sauce-soil-cover.avif`,
  width,
  url: `https://media.crystallize.com/some-tenant/21/9/6/1/@${width}/the-sauce-soil-cover.avif`,
})

export const crystallizeImage = {
  url: "https://media.crystallize.com/some-tenant/21/9/6/1/the-sauce-soil-cover.png",
  altText: "Some alt text",
  key: "some-tenant/21/9/6/1/the-sauce-soil-cover.png",
  variants: [
    crystallizeImageVariant(100),
    crystallizeImageVariant(200),
    crystallizeImageVariant(300),
    crystallizeImageVariant(400),
  ],
}

export const crystallizeProduct = (props = {}) => ({
  shape: {
    name: props.shape || "Product",
  },
  id: props.id || "product-id",
  name: props.name || "Product Name",
  type: "product",
  path: props.path || "/path-to-product",
  components: props.components || [
    {
      id: "issue-title",
      name: "Issue Title",
      type: "singleLine",
      content: {
        text: "Some title",
      },
    },
    {
      id: "description",
      name: "Product description",
      type: "richText",
      content: {
        json: [
          {
            kind: "block",
            type: "paragraph",
            metadata: {},
            children: [
              {
                kind: "inline",
                metadata: {},
                textContent: "Some description",
              },
              {
                kind: "inline",
                metadata: {},
                textContent: "with emphasized content",
                type: "emphasized",
              },
              {
                kind: "inline",
                metadata: {},
                textContent: "and more text",
              },
            ],
          },
          {
            kind: "block",
            type: "paragraph",
            metadata: {},
            children: [
              {
                kind: "inline",
                metadata: {},
                textContent: "Second paragraph",
              },
            ],
          },
        ],
        html: ["<p>Some html</p>", "<p>Some html</p>"],
        plainText: ["Some html", "Some html"],
      },
    },
  ],
  variants: props.variants || [
    {
      id: "variant-1-id",
      name: "Print",
      sku: "variant-1-sku",
      price: 24,
      attributes: [
        {
          attribute: "shouldDisplay",
          value: "true",
        },
        {
          attribute: "combinedPricing",
          value: "false",
        },
      ],
      priceVariants: [
        {
          identifier: "default",
          name: "Default",
          price: 24,
          currency: "sgd",
        },
      ],
      stock: 100,
      isDefault: true,
      images: [crystallizeImage],
      subscriptionPlans: null,
    },
    {
      id: "variant-2-id",
      name: "Online",
      sku: "variant-2-sku",
      price: 5,
      priceVariants: [
        {
          identifier: "default",
          name: "Default",
          price: 5,
          currency: "sgd",
        },
      ],
      stock: 100,
      isDefault: false,
      images: [crystallizeImage],
      subscriptionPlans: null,
    },
  ],
})

export const customer = ({ type, withShippingAddress, country }) => {
  const baseFields = {
    firstName: "Foo",
    lastName: "Bar",
    email: "foo@bar.com",
    phone: "Singapore (+65) 44446666",
  }
  const streetAddress = "1 Foo St"
  const unitNumber = "10-10"
  const shippingAddress = {
    type: Crystallize.CustomerAddressTypes.DELIVERY,
    country: country || "Singapore",
    city: "",
    ...(type === "crystallize"
      ? { street: "1 Foo St, 10-10" }
      : {
          streetAddress,
          unitNumber,
        }),
    postalCode: "S123123",
  }
  const addresses = [
    {
      type: "other",
      email: "foo@bar.com",
      phone: "Singapore (+65) 44446666",
    },
    ...(withShippingAddress ? [shippingAddress] : []),
  ]

  if (type === "crystallize") {
    return { ...baseFields, addresses }
  } else {
    return {
      ...baseFields,
      ...(withShippingAddress
        ? {
            shippingAddress,
          }
        : {}),
    }
  }
}

export const orderFormPayload = ({
  withShippingAddress,
  country,
  productVariants,
  selectedVariantSkus,
  quantity,
  paymentMode,
}) => {
  const product = transformProduct(
    crystallizeProduct({ variants: productVariants })
  )

  return {
    customer: customer({ type: "payload", withShippingAddress, country }),
    paymentMode: paymentMode || Crystallize.PaymentMode.PAYNOW_PAYLAH,
    ...(paymentMode === Crystallize.PaymentMode.STRIPE
      ? { paymentIntentId: "pi_0001" }
      : {}),
    cart: selectedVariantSkus.map(sku => ({
      product,
      selectedVariant: product.variants[sku],
      quantity: quantity,
    })),
  }
}

export const crystallizeOrder = (props = {}) => ({
  customer:
    props.customer ||
    customer({ type: "crystallize", withShippingAddress: true }),
  cart: props.cart || [
    {
      price: {
        net: 24,
      },
      name: "The Sauce #1 - Soil (Print)",
      sku: "the-sauce-1-soil-print-1630910043737",
      quantity: 1,
      imageUrl: "http://localhost:8000/some-image.jpg",
    },
  ],
  meta: props.meta || [
    {
      key: Crystallize.OrderMetaKey.ORDER_NUMBER,
      value: "2091c",
    },
    {
      key: Crystallize.OrderMetaKey.PAYMENT_MODE,
      value: "PayNow/PayLah!",
    },
  ],
  total: {
    net: 24,
  },
})
